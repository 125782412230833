import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 2xl:gap-5 gap-3 mb-5 relative" }
const _hoisted_5 = { class: "relative" }
const _hoisted_6 = { class: "box relative" }
const _hoisted_7 = { class: "mb-3 mt-14 space-y-6" }
const _hoisted_8 = { class: "font-bold" }
const _hoisted_9 = { class: "font-bold" }
const _hoisted_10 = { class: "space-x-4 mb-3" }
const _hoisted_11 = { class: "font-bold ml-4 mt-4" }
const _hoisted_12 = { class: "font-bold mt-4" }
const _hoisted_13 = { class: "font-bold mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: `/${$setup.SETTING_INFORMATION.path}`
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode(" Setting Information ")
          ])),
          _: 1
        }, 8, ["to"])
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", {
            class: _normalizeClass(['my-3', _ctx.styles['decoration-child']])
          }, [
            _cache[6] || (_cache[6] = _createElementVNode("h1", {
              class: _normalizeClass([
                'text-left text-xl font-bold box absolute top-0 left-0 w-full',
              ])
            }, " Scope 2 Emission ", -1)),
            _createElementVNode("ul", _hoisted_7, [
              _createElementVNode("li", _hoisted_8, [
                _createVNode(_component_router_link, {
                  to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.SCOPE_2_EMISSION_TARGETS.path}`
                }, {
                  default: _withCtx(() => _cache[1] || (_cache[1] = [
                    _createTextVNode(" > Scope 2 Renewable Target ")
                  ])),
                  _: 1
                }, 8, ["to"])
              ]),
              _createElementVNode("li", _hoisted_9, [
                _createVNode(_component_router_link, {
                  to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.SCOPE_2_RENEWABLES_PERFORMMANACE.path}`
                }, {
                  default: _withCtx(() => _cache[2] || (_cache[2] = [
                    _createTextVNode(" > Scope 2 Renewables Performance ")
                  ])),
                  _: 1
                }, 8, ["to"]),
                _createElementVNode("ol", _hoisted_10, [
                  _createElementVNode("li", _hoisted_11, [
                    _createVNode(_component_router_link, {
                      to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#energy_loads`
                    }, {
                      default: _withCtx(() => _cache[3] || (_cache[3] = [
                        _createTextVNode(" > Energy Consumption (kWh) ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createElementVNode("li", _hoisted_12, [
                    _createVNode(_component_router_link, {
                      to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#renewables_energy`
                    }, {
                      default: _withCtx(() => _cache[4] || (_cache[4] = [
                        _createTextVNode(" > Renewables Attribute (kWh) ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ]),
                  _createElementVNode("li", _hoisted_13, [
                    _createVNode(_component_router_link, {
                      to: `/${$setup.SETTING_INFORMATION.path}/${$setup.SETTING_INFORMATION.S2RP_INPUT_MANUAL.path}#solar_pv_generation`
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode(" > Onsite PV Generation (kWh) ")
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ])
                ])
              ])
            ])
          ], 2)
        ])
      ])
    ])
  ]))
}