import { defineComponent as _defineComponent } from 'vue'
import { SETTING_INFORMATION, DASHBOARD, USER } from "@ems/constants";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props, { expose: __expose }) {
  __expose();


const __returned__ = { get SETTING_INFORMATION() { return SETTING_INFORMATION }, get DASHBOARD() { return DASHBOARD }, get USER() { return USER } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})